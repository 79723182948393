/**
 * SIDC Report Generator Core
 * Our Endpoints are powered by .Net Core, Onion Architecture, CQRS with MediatR Library,Entity Framework Core - Code First,Repository Pattern - Generic, Swagger UI, API Versioning,Fluent Validation and Automapper.
 *
 * OpenAPI spec version: v1
 * Contact: sidcmisdepartment@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { GetDailySalesPerCategoryPerBranchQuery } from '../model/getDailySalesPerCategoryPerBranchQuery';
import { GetDailySalesReportPerBranchQuery } from '../model/getDailySalesReportPerBranchQuery';
import { GetItemProfitPerCategoryPerRegionQuery } from '../model/getItemProfitPerCategoryPerRegionQuery';
import { GetItemSalesinValuePerCategoryPerRegionQuery } from '../model/getItemSalesinValuePerCategoryPerRegionQuery';
import { GetItemSoldPerCategoryPerRegionQuery } from '../model/getItemSoldPerCategoryPerRegionQuery';
import { GetMonthlySalesReportPerRegionQuery } from '../model/getMonthlySalesReportPerRegionQuery';
import { GetRiceFeedsSalesQuantityPerBranchQuery } from '../model/getRiceFeedsSalesQuantityPerBranchQuery';
import { GetRiceFeedsSalesQuantityPerRegionQuery } from '../model/getRiceFeedsSalesQuantityPerRegionQuery';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class SalesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param version 
     * @param branchCode 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesCheckMissingRcTransactionsGet(version: string, branchCode?: string, year?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesCheckMissingRcTransactionsGet(version: string, branchCode?: string, year?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesCheckMissingRcTransactionsGet(version: string, branchCode?: string, year?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesCheckMissingRcTransactionsGet(version: string, branchCode?: string, year?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesCheckMissingRcTransactionsGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (branchCode !== undefined && branchCode !== null) {
            queryParameters = queryParameters.set('branchCode', <any>branchCode);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/check-missing-rc-transactions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param branchCode 
     * @param year 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesCheckMissingSalesTransactionsGet(version: string, branchCode?: string, year?: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesCheckMissingSalesTransactionsGet(version: string, branchCode?: string, year?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesCheckMissingSalesTransactionsGet(version: string, branchCode?: string, year?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesCheckMissingSalesTransactionsGet(version: string, branchCode?: string, year?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesCheckMissingSalesTransactionsGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (branchCode !== undefined && branchCode !== null) {
            queryParameters = queryParameters.set('branchCode', <any>branchCode);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/check-missing-sales-transactions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesDailySalesPerBranchPost(version: string, body?: GetDailySalesReportPerBranchQuery, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesDailySalesPerBranchPost(version: string, body?: GetDailySalesReportPerBranchQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesDailySalesPerBranchPost(version: string, body?: GetDailySalesReportPerBranchQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesDailySalesPerBranchPost(version: string, body?: GetDailySalesReportPerBranchQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesDailySalesPerBranchPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/daily-sales-per-branch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesDailySalesPerCategoryPerBranchPost(version: string, body?: GetDailySalesPerCategoryPerBranchQuery, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesDailySalesPerCategoryPerBranchPost(version: string, body?: GetDailySalesPerCategoryPerBranchQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesDailySalesPerCategoryPerBranchPost(version: string, body?: GetDailySalesPerCategoryPerBranchQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesDailySalesPerCategoryPerBranchPost(version: string, body?: GetDailySalesPerCategoryPerBranchQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesDailySalesPerCategoryPerBranchPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/daily-sales-per-category-per-branch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param branchCode 
     * @param dateFrom 
     * @param dateTo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesFoottrafficPerbranchcodeDaterangeGet(version: string, branchCode?: string, dateFrom?: Date, dateTo?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesFoottrafficPerbranchcodeDaterangeGet(version: string, branchCode?: string, dateFrom?: Date, dateTo?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesFoottrafficPerbranchcodeDaterangeGet(version: string, branchCode?: string, dateFrom?: Date, dateTo?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesFoottrafficPerbranchcodeDaterangeGet(version: string, branchCode?: string, dateFrom?: Date, dateTo?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesFoottrafficPerbranchcodeDaterangeGet.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (branchCode !== undefined && branchCode !== null) {
            queryParameters = queryParameters.set('branchCode', <any>branchCode);
        }
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('dateFrom', <any>dateFrom.toISOString());
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('dateTo', <any>dateTo.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/foottraffic-perbranchcode-daterange`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param branchCode 
     * @param year 
     * @param categoryCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesItemProfitPerCategoryPerBranchGet(version: string, branchCode?: string, year?: number, categoryCode?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesItemProfitPerCategoryPerBranchGet(version: string, branchCode?: string, year?: number, categoryCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesItemProfitPerCategoryPerBranchGet(version: string, branchCode?: string, year?: number, categoryCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesItemProfitPerCategoryPerBranchGet(version: string, branchCode?: string, year?: number, categoryCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesItemProfitPerCategoryPerBranchGet.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (branchCode !== undefined && branchCode !== null) {
            queryParameters = queryParameters.set('branchCode', <any>branchCode);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('Year', <any>year);
        }
        if (categoryCode !== undefined && categoryCode !== null) {
            queryParameters = queryParameters.set('categoryCode', <any>categoryCode);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/item-profit-per-category-per-branch`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesItemProfitPerCategoryPerRegionPost(version: string, body?: GetItemProfitPerCategoryPerRegionQuery, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesItemProfitPerCategoryPerRegionPost(version: string, body?: GetItemProfitPerCategoryPerRegionQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesItemProfitPerCategoryPerRegionPost(version: string, body?: GetItemProfitPerCategoryPerRegionQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesItemProfitPerCategoryPerRegionPost(version: string, body?: GetItemProfitPerCategoryPerRegionQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesItemProfitPerCategoryPerRegionPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/item-profit-per-category-per-region`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param branchCode 
     * @param year 
     * @param categoryCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesItemSalesInValuePerCategoryPerBranchGet(version: string, branchCode?: string, year?: number, categoryCode?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesItemSalesInValuePerCategoryPerBranchGet(version: string, branchCode?: string, year?: number, categoryCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesItemSalesInValuePerCategoryPerBranchGet(version: string, branchCode?: string, year?: number, categoryCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesItemSalesInValuePerCategoryPerBranchGet(version: string, branchCode?: string, year?: number, categoryCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesItemSalesInValuePerCategoryPerBranchGet.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (branchCode !== undefined && branchCode !== null) {
            queryParameters = queryParameters.set('branchCode', <any>branchCode);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('Year', <any>year);
        }
        if (categoryCode !== undefined && categoryCode !== null) {
            queryParameters = queryParameters.set('categoryCode', <any>categoryCode);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/item-sales-in-value-per-category-per-branch`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesItemSalesInValuePerCategoryPerRegionPost(version: string, body?: GetItemSalesinValuePerCategoryPerRegionQuery, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesItemSalesInValuePerCategoryPerRegionPost(version: string, body?: GetItemSalesinValuePerCategoryPerRegionQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesItemSalesInValuePerCategoryPerRegionPost(version: string, body?: GetItemSalesinValuePerCategoryPerRegionQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesItemSalesInValuePerCategoryPerRegionPost(version: string, body?: GetItemSalesinValuePerCategoryPerRegionQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesItemSalesInValuePerCategoryPerRegionPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/item-sales-in-value-per-category-per-region`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param branchCode 
     * @param year 
     * @param categoryCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesItemSoldPerCategoryPerBranchGet(version: string, branchCode?: string, year?: number, categoryCode?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesItemSoldPerCategoryPerBranchGet(version: string, branchCode?: string, year?: number, categoryCode?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesItemSoldPerCategoryPerBranchGet(version: string, branchCode?: string, year?: number, categoryCode?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesItemSoldPerCategoryPerBranchGet(version: string, branchCode?: string, year?: number, categoryCode?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesItemSoldPerCategoryPerBranchGet.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (branchCode !== undefined && branchCode !== null) {
            queryParameters = queryParameters.set('branchCode', <any>branchCode);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('Year', <any>year);
        }
        if (categoryCode !== undefined && categoryCode !== null) {
            queryParameters = queryParameters.set('categoryCode', <any>categoryCode);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/item-sold-per-category-per-branch`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesItemSoldPerCategoryPerRegionPost(version: string, body?: GetItemSoldPerCategoryPerRegionQuery, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesItemSoldPerCategoryPerRegionPost(version: string, body?: GetItemSoldPerCategoryPerRegionQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesItemSoldPerCategoryPerRegionPost(version: string, body?: GetItemSoldPerCategoryPerRegionQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesItemSoldPerCategoryPerRegionPost(version: string, body?: GetItemSoldPerCategoryPerRegionQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesItemSoldPerCategoryPerRegionPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/item-sold-per-category-per-region`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesMonthlySalesPerRegionPost(version: string, body?: GetMonthlySalesReportPerRegionQuery, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesMonthlySalesPerRegionPost(version: string, body?: GetMonthlySalesReportPerRegionQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesMonthlySalesPerRegionPost(version: string, body?: GetMonthlySalesReportPerRegionQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesMonthlySalesPerRegionPost(version: string, body?: GetMonthlySalesReportPerRegionQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesMonthlySalesPerRegionPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/monthly-sales-per-region`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesRiceFeedsSalesPerQuantityPerBranchPost(version: string, body?: GetRiceFeedsSalesQuantityPerBranchQuery, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesRiceFeedsSalesPerQuantityPerBranchPost(version: string, body?: GetRiceFeedsSalesQuantityPerBranchQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesRiceFeedsSalesPerQuantityPerBranchPost(version: string, body?: GetRiceFeedsSalesQuantityPerBranchQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesRiceFeedsSalesPerQuantityPerBranchPost(version: string, body?: GetRiceFeedsSalesQuantityPerBranchQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesRiceFeedsSalesPerQuantityPerBranchPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/rice-feeds-sales-per-quantity-per-branch`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesRiceFeedsSalesPerQuantityPerRegionPost(version: string, body?: GetRiceFeedsSalesQuantityPerRegionQuery, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesRiceFeedsSalesPerQuantityPerRegionPost(version: string, body?: GetRiceFeedsSalesQuantityPerRegionQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesRiceFeedsSalesPerQuantityPerRegionPost(version: string, body?: GetRiceFeedsSalesQuantityPerRegionQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesRiceFeedsSalesPerQuantityPerRegionPost(version: string, body?: GetRiceFeedsSalesQuantityPerRegionQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesRiceFeedsSalesPerQuantityPerRegionPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/rice-feeds-sales-per-quantity-per-region`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param branchCode 
     * @param numberOfProducts 
     * @param dateFrom 
     * @param dateTo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesTopPerformingProductsPerBranchGet(version: string, branchCode?: string, numberOfProducts?: number, dateFrom?: Date, dateTo?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesTopPerformingProductsPerBranchGet(version: string, branchCode?: string, numberOfProducts?: number, dateFrom?: Date, dateTo?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesTopPerformingProductsPerBranchGet(version: string, branchCode?: string, numberOfProducts?: number, dateFrom?: Date, dateTo?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesTopPerformingProductsPerBranchGet(version: string, branchCode?: string, numberOfProducts?: number, dateFrom?: Date, dateTo?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesTopPerformingProductsPerBranchGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (branchCode !== undefined && branchCode !== null) {
            queryParameters = queryParameters.set('branchCode', <any>branchCode);
        }
        if (numberOfProducts !== undefined && numberOfProducts !== null) {
            queryParameters = queryParameters.set('numberOfProducts', <any>numberOfProducts);
        }
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('dateFrom', <any>dateFrom.toISOString());
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('dateTo', <any>dateTo.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/top-performing-products-per-branch`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param version 
     * @param regionCode 
     * @param numberOfProducts 
     * @param dateFrom 
     * @param dateTo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiVversionDatawarehouseSalesTopPerformingProductsPerRegionGet(version: string, regionCode?: number, numberOfProducts?: number, dateFrom?: Date, dateTo?: Date, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiVversionDatawarehouseSalesTopPerformingProductsPerRegionGet(version: string, regionCode?: number, numberOfProducts?: number, dateFrom?: Date, dateTo?: Date, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiVversionDatawarehouseSalesTopPerformingProductsPerRegionGet(version: string, regionCode?: number, numberOfProducts?: number, dateFrom?: Date, dateTo?: Date, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiVversionDatawarehouseSalesTopPerformingProductsPerRegionGet(version: string, regionCode?: number, numberOfProducts?: number, dateFrom?: Date, dateTo?: Date, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (version === null || version === undefined) {
            throw new Error('Required parameter version was null or undefined when calling apiVversionDatawarehouseSalesTopPerformingProductsPerRegionGet.');
        }





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (regionCode !== undefined && regionCode !== null) {
            queryParameters = queryParameters.set('regionCode', <any>regionCode);
        }
        if (numberOfProducts !== undefined && numberOfProducts !== null) {
            queryParameters = queryParameters.set('numberOfProducts', <any>numberOfProducts);
        }
        if (dateFrom !== undefined && dateFrom !== null) {
            queryParameters = queryParameters.set('dateFrom', <any>dateFrom.toISOString());
        }
        if (dateTo !== undefined && dateTo !== null) {
            queryParameters = queryParameters.set('dateTo', <any>dateTo.toISOString());
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/v${encodeURIComponent(String(version))}/datawarehouse/Sales/top-performing-products-per-region`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
