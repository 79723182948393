/**
 * SIDC SAP API SERVER for Inventory Management
 * Our Endpoints are powered by .Net Core, Onion Architecture, CQRS with MediatR Library,Entity Framework Core - Code First,Repository Pattern - Generic, Swagger UI, API Versioning,Fluent Validation and Automapper.
 *
 * OpenAPI spec version: v1
 * Contact: sidcmisdepartment@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface CreateItemsUnitOfMeasuresForValidationCommand { 
    ugpEntry?: number;
    itemCode?: string;
    idStock?: string;
    unitOfMeasure?: string;
    conversion?: number;
    barcode?: string;
    isBaseUOM?: boolean;
    isActive?: boolean;
    toIsActive?: boolean;
    toItemCode?: string;
    toIDStock?: string;
    toUnitOfMeasure?: string;
    toConversion?: number;
    toBarcode?: string;
    toIsBaseUOM?: boolean;
    remarks?: string;
    status?: string;
    evaluatedBy?: string;
    evaluatedByName?: string;
}